import { defineComponent } from 'vue'

export const TonIcon = defineComponent({
	name: 'TonIcon',
	setup: () => {
		return () => (
			<svg
				width="23"
				height="20"
				viewBox="0 0 23 20"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M22.0864 4.17409L12.4966 19.3988C12.3201 19.6775 12.0432 19.8783 11.7229 19.9599C11.4025 20.0415 11.063 19.9976 10.774 19.8374C10.5824 19.7304 10.4205 19.5774 10.3032 19.3922L0.901505 4.16751C0.638094 3.74084 0.499068 3.24946 0.500005 2.74845C0.511878 2.00879 0.817588 1.30403 1.35005 0.788816C1.88252 0.2736 2.59825 -0.00999118 3.3402 0.000269018H19.673C21.2317 0.000269018 22.5 1.22632 22.5 2.74187C22.5 3.24633 22.357 3.74201 22.0853 4.17409M3.2093 3.66525L10.2053 14.4178V2.58067H3.9408C3.217 2.58067 2.8925 3.0588 3.2104 3.66525M12.7936 14.4178L19.7896 3.66525C20.1119 3.0588 19.7819 2.58067 19.0581 2.58067H12.7936V14.4178Z"
					fill="white"
				/>
			</svg>
		)
	}
})
